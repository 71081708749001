<template>
  <nav id="navbarBlur" class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    :class="[isRTL ? 'top-1 position-sticky z-index-sticky' : '']" v-bind="$attrs" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none" :class="isRTL ? 'me-3' : ''">
        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
            <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
            <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
          </div>
        </a>
      </div>
      <breadcrumbs class="ms-5" :current-directory="currentDirectory" :current-page="currentRouteName"
        :text-white="textWhite" />
      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="isRTL ? 'px-0' : 'me-sm-4'">
        <div class="pe-md-4 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <div class="input-group">
            <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
            <input type="text" class="form-control custom_input" :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'" />
          </div>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <router-link :to="{ name: 'Signin Illustration' }" class="px-0 nav-link font-weight-bold m-0">
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-2'"></i>
              <span v-if="isRTL" class="d-sm-inline d-none">يسجل دخول</span>
              <span v-else class="d-sm-inline d-none" @click="logout">Log Out</span>
            </router-link>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a id="iconNavbarSidenav" href="#" class="p-0 nav-link text-body" @click="toggleSidebar">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
<li class="nav-item dropdown d-flex align-items-center ps-2">
  <a id="dropdownMenuButton" href="#" class="p-0 nav-link m-0" :class="[textWhite ? textWhite : 'text-body', showMenu ? 'show' : '']" data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
    <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
  </a>
   <ul
    class=" dropdown-menu dropdown-menu-end me-sm-n4"
    :class="showMenu ? 'show' : ''"
    aria-labelledby="dropdownMenuButton"
  >
    <li class="mb-2">
      <a class="dropdown-item border-radius-md" href="javascript:;">
        <!-- <div class="py-1 d-flex"> -->
         
          <!-- <div class="d-flex flex-column justify-content-center"> -->
            <!-- <h6 class="mb-1 text-sm font-weight-normal">
              <span class="font-weight-bold">New message</span> from Laur
            </h6> -->
            <router-link :to="{ name: 'Reset Basic'}">
            <p class="mb-0 text-xs text-secondary">
              Reset Password
            </p>
            </router-link>
          <!-- </div> -->
        <!-- </div> -->
      </a>
    </li>
  </ul>
</li>

        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex";
import Breadcrumbs from "../Breadcrumbs.vue";
import { logout } from '../../services/api.service'

export default {
  name: "Navbar",
  components: {
    Breadcrumbs
  },
  props: {
    minNav: {
      type: Function,
      default: () => { },
    },
    textWhite: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
  },
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    logout() {
      logout();
      setTimeout(() => {
        this.$router.push({ name: "Signin Illustration" });
      }, 10);
    }
  },
};
</script>
<style lang="scss">
.form-control.custom_input:focus {
  box-shadow: none !important;
  border-color: #d2d6da !important;
}

.input-group .form-control.custom_input:focus {
  border-left: 0px solid transparent !important;
  border-right: 1px solid #d2d6da !important;
}
</style>
