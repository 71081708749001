<template>
  <aside
    id="sidenav-main"
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
  >
    <div class="sidenav-header custom_header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand company_logo d-flex align-items-center" to="/">
      <div class="d-flex align-items-center">
       <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
          </div>
        <!-- <span class="ms-1 font-weight-bold">Trilectric</span> -->
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :card-bg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/tri.png";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
<style lang="scss">
.g-sidenav-show.g-sidenav-hidden{
    .custom_header{
   .navbar-brand{
      padding: 15px 4px 25px !important;
   }
  }
}
.g-sidenav-hidden .navbar-vertical .navbar-brand span {
    display: none;
}
.g-sidenav-show{
.custom_header{
   .navbar-brand{
      padding: 7px 32px!important;
      span{
        color: black;
        font-weight: bold;
        font-size: 18px;
        margin-right: 30px;
      }
}
}
}
.sidenav-header{
  height:unset!important;
}
</style>
