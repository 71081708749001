<template>
  <div class="card widget-calendar calander-card">
    <!-- Card header -->
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">{{ title }}</h6>
      <div class="d-flex">
        <div class="mb-0 text-sm p font-weight-bold widget-calendar-day">
          {{ day }}
        </div>
        <span>,&nbsp;</span>
        <div class="mb-1 text-sm p font-weight-bold widget-calendar-year">
          {{ year }}
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div :id="id" data-toggle="widget-calendar"></div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {getSitesForUser} from '../services/api.service'
let calendar;

export default {
  name: "Calendar",
  data() {
    return {
      calendar: null,
      siteIdArr:[],
    };
  },
  props: {
    id: {
      type: String,
      default: "widget-calendar",
    },
    title: {
      type: String,
      default: "Calendar",
    },
    day: {
      type: String,
    },
    year: {
      type: String,
    },
    initialView: {
      type: String,
      default: "dayGridMonth",
    },
    // initialDate: {
    //   type: String,
    //   default: "2022-02-24",
    // },
    selectable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  mounted:async function() {
      this.siteIdArr= await getSitesForUser();
    calendar = new Calendar(document.getElementById(this.id), {
      contentHeight: "auto",
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: this.initialView,
      selectable: this.selectable,
      editable: this.editable,
      initialDate: this.initialDate,
      // events:this.events,
      events: {
        url: `https://amc-admin.trillectric.com/amcDashboard?sites=${this.siteIdArr}`,
      },
      eventSourceSuccess: (data) => {
        for (const event of data) {
          if(event.status=='PENDING'){
            event.classNames = ['pending-style'];
          }
          else if(event.status=='ASSIGNED'){
            event.classNames = ['assigned-style'];
          }
          else if(event.status=='COMPLETED'){
            event.classNames = ['completed-style'];
         }
         else if(event.status=='HOLD'){
            event.classNames = ['hold-style'];
         }
         else if(event.status=='ISSUE'){
            event.classNames = ['issue-style'];
         }
         
        }
        return data;
      }, 
      eventClick: (info) => {
        console.log("***info***: ", info.url);
        this.$router.push({
          // path: "/amc/amcJobCardDetail",
          // query: { jobId: "123" },
             path:info.url
        });
      },
      headerToolbar: {
        left: "prev,next,today",
        center: "title",
        right: "dayGridDay dayGridWeek dayGridMonth",
      },
      views: {
        dayGrid: {
          dayMaxEventRows: 4,
        },
        month: {
          titleFormat: {
            month: "long",
            year: "numeric",
          },
        },
        agendaWeek: {
          titleFormat: {
            month: "long",
            year: "numeric",
            day: "numeric",
          },
        },
        agendaDay: {
          titleFormat: {
            month: "short",
            year: "numeric",
            day: "numeric",
          },
        },
      },
    });
    calendar.render();
  },
  beforeUnmount() {
    if (calendar) {
      calendar.destroy();
    }
  },
};
</script>
<style>
.calander-card {
  margin-bottom: 100px;
}
.completed-style{
  background-color:#69b98d;
}
.pending-style{
  background-color:#e17676;
}
.assigned-style{
  background-color:#8fb6db;
}
.hold-style{
  background-color:#e8e19e;
}
.issue-style{
  background-color:#f1b975;
}
</style>
