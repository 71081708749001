<template>
    <div class="dashboard-container container-fluid">
      <div class="row">
        <div class="col-lg-12 position-relative z-index-2">
               <Calander />   
         </div>
      </div>
    </div>
  </template>
<script>
import Calander from "../../examples/Calendar.vue"
export default {
  name: "Amc Dashboard",
  components: {
    Calander
  },
  data() {
    return {
      
    };
  },
};
</script>
<style>
.dashboard-container {
  margin-bottom: 100px;
  height: calc(100vh + 100px);
}
</style>
