<!-- <template>
  <div v-if="loading" class="loader">
    <div
      v-for="(_, loadingIndex) in Array(5)"
      :key="loadingIndex"
      class="spinner-grow text-dark"
      role="status"
    ></div>
  </div>
  <div v-if="!loading" class="data">
    <div v-if="listLoading" class="inner-loader">
      <div
        v-for="(_, loadingIndex) in Array(5)"
        :key="loadingIndex"
        class="spinner-grow text-dark"
        role="status"
      ></div>
    </div>
    <div v-if="!listLoading" class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="table-responsive">
            <table id="sites" class="table table-flush responsive nowrap">
              <thead class="thead-light">
                <tr>
                  <th>Site</th>
                  <th>Status</th>
                  <th>Job Type</th>
                  <th>Service Date</th>
                  <th>Assigned</th>
                </tr>
              </thead>
              <tbody>
                <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>

              </tbody>
              
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
// import DataTable from "datatables.net-dt";
// import "datatables.net-responsive-dt";
import {getSitesForUser,getAmcList} from '../../services/api.service'
export default {
  data() {
    return {
      loading: false,
      listLoading: false,
      dataTable: null,
      siteIdArr:[],
      amcData:[]
    };
  },
  computed: {
   
  },
  watch: {
   
  },
  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  },
  mounted: async function () {
       this.siteIdArr=await getSitesForUser('userId');
       this.amcData=await getAmcList(this.siteIdArr)
    // if (document.getElementById("sites")) {
    //   this.dataTable = new DataTable("#sites", {
    //     searchable: true,
    //     serverSide: true,
    //     processing: true,
    //     ajax: {
    //       url: `http://localhost:3000/amcList?sites=${this.siteIdArr}`,
    //       // url: `https://amc-admin.trillectric.com/amcList`,
    //       type: "GET",
    //       // headers: {
    //       //   Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       // },
    //     },
    //     columnDefs: [
    //       {
    //         targets: [1],
    //         render: function (data) {
    //           console.log(data);
    //           if (data === "HOLD") {
    //             return `<p style="color: green; font-weight: normal;">${data}</p>`;
    //           } else if(data==="PENDING") {
    //             return `<span style="color: red ;font-weight: bold;">${data}</span>`;
    //           }
    //           else if(data==="ALERT") {
    //             return `<p style="color: orange ;font-weight: bold;">${data}</p>`;
    //           }else{
    //             return `<p>${data}</p>`;
    //           }
    //         },
    //       },
    //     ],
    //   });
    // }
  },
  updated: function () {
 
  },
  methods: {
    updateDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
      }
    },
  },
};
</script>

<style scoped lang="scss">
// @import "https://cdn.datatables.net/2.0.1/css/dataTables.dataTables.css";

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}
.table-responsive {
  overflow-x: hidden;
}
.inner-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 90vh;
}

.filter-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-col {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  label {
    margin-right: 0.625rem;
  }

  .filter-dropdown {
    padding: 0.5rem;
    border: 0.063rem solid #ddd;
    border-radius: 0.25rem;
  }
}
.site-btn {
  cursor: pointer;
}
</style> -->


<template>
  <div v-if="loading" class="loader">
    <div
      v-for="(_, loadingIndex) in Array(5)"
      :key="loadingIndex"
      class="spinner-grow text-dark"
      role="status"
    ></div>
  </div>
  <div v-if="!loading" class="data">
    <div v-if="listLoading" class="inner-loader">
      <div
        v-for="(_, loadingIndex) in Array(5)"
        :key="loadingIndex"
        class="spinner-grow text-dark"
        role="status"
      ></div>
    </div>
    <div v-if="!listLoading" class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="table-responsive">
            <table  id="sites" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Site</th>
                  <th>Status</th>
                  <th>Job Type</th>
                  <th>Service Date</th>
                  <th>Assigned</th>
                </tr>
              </thead>
              <tbody v-if="amcList.length>0">
                <tr v-for="(amc,idx) in amcList" :key="idx">
                  <td class="text-sm font-weight-normal site-btn btn-link">
                    <router-link
                      :to="{
                        name: 'AMC Job Card Detail',
                        query: {
                          jobId: amc.jobId ,
                        },
                      }"
                    >
                      {{ amc.siteName }}
                    </router-link>
                  </td>
                  <td class="text-sm font-weight-normal"
                    :style="{
                      color: getColor(amc.status)
                    }"
                  
                  >
                    {{ amc.status}}
                  </td>
                  <td class="text-sm font-weight-normal">{{ amc.jobType}}</td>
                  <td class="text-sm font-weight-normal">
                    {{ amc.serviceDate}}
                  </td>
                  <td
                    class="text-sm"
                  >
                    {{amc.serviceBy}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import {getSitesForUser,getAmcList } from "../../services/api.service";
export default {
  data() {
    return {
      loading: false,
      listLoading: false,
      dataTable: null,
      amcList:[],
      siteIdArr:[],
    }
  },
  computed: {
   
  },
  watch: {
   
  },
  mounted: async function () {
    this.loading = true;
    this.siteIdArr=await getSitesForUser();
    this.amcList=await getAmcList(this.siteIdArr);
    this.loading = false;
  },
  updated: function () {
    if (document.getElementById("sites")) {
      this.dataTable = new DataTable("#sites", {
        searchable: true,
        fixedHeight: true,
      });
    }
  },
  methods: {
    getColor(status){
    if(status=="COMPLETED"){
    return 'green';
    }
    if(status=="HOLD"){
    return 'orange';
    }
    if(status=="ASSIGNED"){
    return 'blue';
    }
    if(status=="PENDING"){
    return 'red';
    }
    if(status=="ISSUE"){
    return 'red';
    }
    }
  },
};
</script>

<style scoped lang="scss">
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.inner-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 90vh;
}

.filter-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-col {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  label {
    margin-right: 0.625rem;
  }

  .filter-dropdown {
    padding: 0.5rem;
    border: 0.063rem solid #ddd;
    border-radius: 0.25rem;
  }
}
.site-btn {
  cursor: pointer;
}
</style>