import axios from "axios";
import { formatDate, getValueWithSuffix } from "../utils";

const URL = process.env.VUE_APP_API_URL;

const api = async (url) => {
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    console.log(url);
    return response;
  } catch (error) {
    return {};
  }
};

const login = async ({ username, password }) => {
  try {
    const response = await axios.post(`${URL}/login`, {
      username,
      password,
    });
    const { data } = response;
    localStorage.setItem("token", data.token);

    return data.token != null;
  } catch (error) {
    console.log(error);
  }
  return false;
};

const logout = () => {
  localStorage.removeItem("token");
};

const getSiteData = async (discom, assetType, states) => {
  const { data } = await api(
    `${URL}/site/status?discom=${discom}&assetType=${assetType}&state=${states}`
  );
  let statusData = {
      labels: [],
      label: "",
      data: [],
      total: 0,
    },
    operationalData = {
      labels: [],
      label: "",
      data: [],
      total: 0,
    };
  if (!data.data) return { statusData, operationalData };
  const { status, operationalStatus } = data.data;
  status.map((record) => {
    statusData.labels.push(record.status);
    statusData.data.push(record.count);
    statusData.total += record.count;
  });
  operationalStatus.map((record) => {
    operationalData.labels.push(record.status);
    operationalData.data.push(record.count);
    operationalData.total += record.count;
  });
  return { statusData, operationalData };
};

const getConsumptionStatsData = async (
  start,
  end,
  discom,
  assetType,
  state,
  amc,
  siteId = ""
) => {
  const { data } = await api(
    `${URL}/stats/consumption/date?start=${start}&end=${end}&assetType=${assetType}&discom=${discom}&state=${state}&amc=${amc}&siteId=${siteId}`
  );
  let labels = [];
  let totalEnergy = 0;
  let { total = 0, count = 0, data: values = [] } = data;
  values = values.map((record) => {
    labels.push(record.date);
    totalEnergy += record.energy;
    return record.energy;
  });
  return {
    total,
    count,
    totalEnergy,
    labels,
    data: values,
  };
};

const getProductionStatsData = async (
  start,
  end,
  discom,
  assetType,
  state,
  amc,
  siteId = ""
) => {
  const { data } = await api(
    `${URL}/stats/production/date?start=${start}&end=${end}&assetType=${assetType}&discom=${discom}&state=${state}&amc=${amc}&siteId=${siteId}`
  );
  let labels = [];
  let totalPower = 0;
  let { total = 0, count = 0, data: values = [] } = data;
  values = values.map((record) => {
    labels.push(record.date);
    totalPower += record.power;
    return record.power;
  });
  return {
    total,
    count,
    totalPower,
    labels,
    data: values,
  };
};

const getDropDownData = async () => {
  const { data } = await api(`${URL}/site/filters`);
  const {
    assetType = [],
    discom = [],
    operationalStatus = [],
    status = [],
    state = [],
    amc = [],
    gridLink = [],
  } = data.data || {};
  return { assetType, discom, operationalStatus, status, state, amc, gridLink };
};

const getOnOffTrackData = async (
  start,
  end,
  discom,
  assetType,
  states,
  amc,
  siteId = ""
) => {
  const { data } = await api(
    `${URL}/track/status/date?start=${start}&end=${end}&assetType=${assetType}&discom=${discom}&state=${states}&amc=${amc}&siteId=${siteId}`
  );
  const result = {
    graph: {
      labels: [],
      data: {
        online: [],
        offline: [],
      },
    },
    pie: {
      status: {
        labels: ["ONLINE", "OFFLINE"],
        data: [],
      },
      operationalStatus: {
        labels: ["NORMAL", "ALERT"],
        data: [],
      },
    },
  };
  const keys = Object.keys(result.graph.data);
  if (!data.status.data) return result;
  data.status.data.map((record) => {
    result.graph.labels.push(record.date);
    keys.map((key) => {
      const value = record[key] || 0;
      result.graph.data[key].push(siteId ? (value > 0 ? 1 : 0) : value);
    });
  });
  const onlineStatus = data.status.data.reduce(
    (prev, record) => (prev + (record.online > 0 ? 1 : 0)),
    0
  );
  const onlineCount = data.status.data.length;
  const onlinePercent = Math.ceil(
    ((onlineStatus / onlineCount) * 100).toFixed(2)
  );
  result.pie.status.data = [onlinePercent, 100 - onlinePercent];
  const normalStatus = data.operationalStatus.data.reduce(
    (prev, record) => (prev + (record.online > 0 ? 1 : 0)),
    0
  );
  const normalCount = data.operationalStatus.data.length;
  const normalPercent = Math.ceil(
    ((normalStatus / normalCount) * 100).toFixed(2)
  );
  result.pie.operationalStatus.data = [normalPercent, 100 - normalPercent];
  return result;
};

const getSites = async (
  discom,
  assetType,
  status,
  operationalStatus,
  state,
  amc,
  gridLink
) => {
  try {
    const { data } = await api(
      `${URL}/site/list?discom=${discom}&assetType=${assetType}&status=${status}&operationalStatus=${operationalStatus}&state=${state}&amc=${amc}&gridLink=${gridLink}`
    );
    return (
      data.data.map((site) => ({
        ...site,
        amcStartDate: site.amc.startDate
          ? `${formatDate(site.amc.startDate)}`
          : "-",
        amcRenewalDate: site.amc.renewalDate
          ? `${formatDate(site.amc.renewalDate)}`
          : "-",
        amcPrice: site.amc.price ? `₹${site.amc.price}` : "-",  
        lifetime: getValueWithSuffix(site.lifetime, "Wh"),
      })) || []
    );
  } catch (error) {
    return [];
  }
};

const getLocations = async (discom, assetType, states, amc, siteId = "") => {
  try {
    const { data } = await api(
      `${URL}/site/map?discom=${discom}&assetType=${assetType}&state=${states}&amc=${amc}&siteId=${siteId}`
    );
    let values = [];
    if (!data.data) return { data: values };
    values = data.data;
    return {
      data: values,
    };
  } catch (error) {
    return [];
  }
};

const getLifetimeProduction = async (siteId) => {
  try {
    const { data } = await api(
      `${URL}/stats/production/lifetime${siteId ? `?siteId=${siteId}` : ""}`
    );
    return data.data;
  } catch (error) {
    return 0;
  }
};



const getJobCardDetail=async (jobId)=>{
 try {
  let  {data:{response}} = await axios.get(`https://amc-admin.trillectric.com/jobDetail?jobId=${jobId}`);
    let jobDetailData={
      ...response,
      serviceDate:formatDate(response?.serviceDate),
    }
  return jobDetailData;
 } catch (error) {
  console.log(error);
 }

}


const getSitesForUser=async ()=>{
  try{
  const { data } = await api(
    `${URL}/site/admin`
  );  
  console.log(data.data);

  return data.data;
  }catch(err){
     console.log(err);
  }


    
}

const getAmcList=async (siteIdArr)=>{
  try{
    const {data} = await axios.get(
      `https://amc-admin.trillectric.com/amcList?sites=${siteIdArr}`
    ); 
    console.log(data); 
    return data.data;
    }catch(err){
       console.log(err);
    }
}

const getAmcListByType=async (siteIdArr,type)=>{
  try{
    const {data} = await axios.get(
      `https://amc-admin.trillectric.com/amcList?sites=${siteIdArr}&type=${type}`
    ); 
    return data.data;
    }catch(err){
       console.log(err);
    } 
}

const getSiteIdData = async (siteId) => {
  try {
    const { data } = await api(`${URL}/site/details/${siteId}`);
    return data.data;
  } catch (error) {
    console.error("Error fetching siteId data:", error);
    return 0;
  }
};
const getAmcData = async () => {
  try {
    const { data } = await api(`${URL}/stats/amc`);
    return data.data;
  } catch (error) {
    return 0;
  }
};

export {
  login,
  logout,
  getSiteData,
  getConsumptionStatsData,
  getProductionStatsData,
  getDropDownData,
  getOnOffTrackData,
  getSites,
  getLocations,
  getLifetimeProduction,
  getAmcData,
  getJobCardDetail,
  getSitesForUser,
  getAmcList,
  getAmcListByType,
  getSiteIdData
};
