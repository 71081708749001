<template>
  <div class="pb-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="filter_section">
          <!-- <h2 class="mb-3 dashboard_title">Filters</h2> -->
          <div class="d-flex justify-content-between mt-4 flex-wrap">
            <div v-for="(value, key, index) in dropdownItems" :key="key" class="dropdownmenu">
              <div v-if="index === 0">
                <div class="dropdown-daterange" style="cursor: pointer" @click="showCalendar()">
                  <div v-if="selected['dateRange'] === 'All Time'">
                    All Time
                  </div>
                  <div v-else>
                    {{ selectedDate[0].format("YYYY-MM-DD") }} -
                    {{ selectedDate[1].format("YYYY-MM-DD") }}
                  </div>
                  <i class="fa fa-calendar"></i>
                </div>
                <div v-show="picker.show.calendar" class="row mt-1 border calander_picker"
                  :onmouseleave="closeDropDownAndCalender">
                  <date-picker v-model="selectedDate" :options="value.options" :first-month="picker.firstDt"
                    :last-month="picker.todayDt" :enable-second-calendar="true" :next-prev-icon="true"
                    @callSelected="selectOption" @onSelect="showCalendar" />
                </div>
              </div>
              <div v-if="index > 0" class="dropdown-toggle" @click="toggleDropdown(index)">
                {{ selectedItems[index] }}
              </div>
              <div v-if="index > 0 && openDropdown === index" class="menu-dropdown"
                :onmouseleave="closeDropDownAndCalender">
                <div v-for="(option, optionIndex) in value.options" :key="optionIndex" class="option_menu"
                  @click="selectOption(index, option, key)">
                  {{ option }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-plain">
          <div class="py-3 px-0 card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex">
                  <h2 class="mb-0 font-weight-bolder section_title">
                    Systems OverView
                  </h2>
                  <div v-if="onlinePieStats.total !== 0" class="ms-2 mt-1 mb-3"
                    style="font-size: 16px; font-weight: bold">
                    <ul class="stats-bullet flex-wrap">
                      <li class="bullet-list">
                        Total Devices Reported - {{ onlinePieStats.total }}
                      </li>

                      <li> All Time Generation - {{ lifetimeProduction }}</li>

                      <li> Total AMCs - {{ amcStats.total }}</li>

                      <!-- <li> Total AMC Cost - ₹ {{ amcStats.totalCost }}</li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-sm-5">
            <div class="card z-index-2">
              <div class="chart-btn btn">
                <router-link :to="{
                  name: 'Sites',
                  query: {
                    discom: selected.discom,
                    assetType: selected.assetType,
                    state: selected.states,
                  },
                }">
                  <div class="p-3 card-body chart-row">
                    <div class="card_title_row d-flex justify-content-between">
                      <h6 class="cardHeader">Total Power Generated</h6>
                    </div>
                    <progress-line-chart id="progress-line-chart-component" :title="productionStats.title"
                      :count="productionStats.count" :stats="productionStats.data" :display-ticks="false"
                      :unit-value="productionStats.unitValue" />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-5">
            <div class="card z-index-2">
              <div class="p-3 card-body chart-row">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Online/Offline ratio</h6>
                </div>

                <div>
                  <pie-chart id="pie-chart-component" height="160" :stats="onlinePieStats" :selected-items="selected"
                    title="status" />
                </div>
                <!-- <div class="my-auto col-5">
                  <span class="badge badge-md badge-dot me-4 d-block text-start">
                    <i class="bg-info"></i>
                    <span class="text-xs text-dark">Working</span>
                  </span>
                  <span class="badge badge-md badge-dot me-4 d-block text-start">
                    <i class="bg-primary"></i>
                    <span class="text-xs text-dark">Faulty</span>
                  </span>
                  <span class="badge badge-md badge-dot me-4 d-block text-start">
                    <i class="bg-dark"></i>
                    <span class="text-xs text-dark">Normal</span>
                  </span>
                  <span class="badge badge-md badge-dot me-4 d-block text-start">
                    <i class="bg-secondary"></i>
                    <span class="text-xs text-dark">Others</span>
                  </span>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-5">
            <div class="card z-index-2">
              <div class="chart-btn btn">
                <router-link :to="{
                  name: 'Sites',
                  query: {
                    discom: selected.discom,
                    assetType: selected.assetType,
                    state: selected.states,
                  },
                }">
                  <div class="p-3 card-body chart-row">
                    <div class="card_title_row d-flex justify-content-between">
                      <h6 class="cardHeader">Total Power Consumed</h6>
                    </div>
                    <progress-line-chart id="progress-line-chart-component" :title="consumptionStats.title"
                      :count="consumptionStats.count" :stats="consumptionStats.data" :display-ticks="false"
                      :unit-value="consumptionStats.unitValue" unit="Wh" />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-5">
            <div class="card z-index-2">
              <div class="p-3 card-body chart-row">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Operational Status</h6>
                </div>
                <div>
                  <pie-chart id="operational-pie-chart" height="160" :stats="operationalPieStats"
                    :selected-items="selected" title="operationalStatus" />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-4 col-lg-5 col-sm-5 mt-sm-0">
            <mini-statistics-card
              title="New Clients"
              value="+3,462"
              :percentage="{
                value: '-2%',
                color: 'text-danger',
              }"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: iconBackground,
              }"
              direction-reverse
            />

            <mini-statistics-card
              title="Sales"
              value="$103,430"
              :percentage="{
                value: '+5%',
                color: 'text-success',
              }"
              :icon="{
                component: 'ni ni-cart',
                background: iconBackground,
              }"
              direction-reverse
            />
          </div> -->
        </div>
        <div class="row mt-3">
          <!-- <div class="col-lg-6 col-sm-5">
            <div class="card z-index-2">
              <div class="p-3 card-body counsumption_info">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Operational Status</h6>
                </div>
                <progress-line-chart
                  id="progress-line-chart-component"
                  :title="consumptionStats.title"
                  :count="consumptionStats.count"
                  :stats="consumptionStats.data"
                />
              </div>
            </div>
          </div> col-lg-6 col-sm-5 -->
          <div class="col-lg-12 mt-3">
            <div class="card z-index-2">
              <div class="p-3 card-body counsumption_info">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">
                    Generation - ( {{ selectedDate[0].format("YYYY-MM-DD") }} -
                    {{ selectedDate[1].format("YYYY-MM-DD") }})
                  </h6>
                </div>
                <progress-line-chart id="progress-line-chart-component" :count="productionStats.count"
                  :stats="productionStats.data" />
              </div>
            </div>
          </div>

          <div class="col-lg-12 mt-3">
            <div class="card z-index-2">
              <div class="p-3 card-body counsumption_info">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Online/Offline Ratio</h6>
                </div>
                <progress-line-chart id="progress-line-chart-component" :count="onlineStats.count"
                  :stats="onlineStats.data" :convert-units="false" />
              </div>
            </div>
          </div>

          <div class="col-lg-12 mt-3">
            <div class="card z-index-2">
              <div class="p-3 card-body counsumption_info">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Consumption Info</h6>
                </div>
                <progress-line-chart id="progress-line-chart-component" :count="consumptionStats.count"
                  :stats="consumptionStats.data" unit="Wh" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-3">
            <player-card :map-locations="locationStats" />
          </div>
        </div>
        <!-- <div class="row mt-3">
          <sales-table
            title="Sales by Country"
            :rows="[
              {
                country: [US, 'United States'],
                sales: 2500,
                value: '$230,900',
                bounce: '29.9%',
              },
              {
                country: [DE, 'Germany'],
                sales: '3.900',
                value: '$440,000',
                bounce: '40.22%',
              },
              {
                country: [GB, 'Great Britain'],
                sales: '1.400',
                value: '$190,700',
                bounce: '23.44%',
              },
              {
                country: [BR, 'Brasil'],
                sales: 562,
                value: '$143,960',
                bounce: '32.14%',
              },
            ]"
          />
        </div> -->
      </div>
    </div>
    <!-- <div class="mt-4 row">
      <div class="mb-4 col-lg-5 mb-lg-0">
        <div class="card z-index-2">
          <div class="p-3 card-body">
            <reports-bar-chart
              id="chart-bar2"
              title="active Users"
              description="(<strong>+23%</strong>) than last week"
              :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                datasets: {
                  label: 'Sales',
                  data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
                },
              }"
              :items="[
                {
                  icon: {
                    color: 'primary',
                    component: faUsers,
                  },
                  label: 'users',
                  progress: { content: '37K', percentage: 60 },
                },
                {
                  icon: { color: 'info', component: faHandPointer },
                  label: 'clicks',
                  progress: { content: '2m', percentage: 90 },
                },
                {
                  icon: { color: 'warning', component: faCreditCard },
                  label: 'Sales',
                  progress: { content: '435$', percentage: 30 },
                },
                {
                  icon: { color: 'danger', component: faScrewdriverWrench },
                  label: 'Items',
                  progress: { content: '43', percentage: 50 },
                },
              ]"
            >
            </reports-bar-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="card z-index-2">
          <gradient-line-chart
            id="chart-line"
            title="Gradient Line Chart"
            description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>4% more</span> in 2021"
            :chart="{
              labels: [
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              datasets: [
                {
                  label: 'Mobile Apps',
                  data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                },
                {
                  label: 'Websites',
                  data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
                },
              ],
            }"
          />
        </div>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-12">
        <globe width="700" height="600" />
      </div>
    </div> -->
  </div>
</template>
<script>
// import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import ProgressLineChart from "../pages/projects/components/ProgressLineChart.vue";
// import DefaultLineChart from "../../examples/Charts/DefaultLineChart.vue";
import PlayerCard from "./components/PlayerCard.vue";
// import ReportsBarChart from "../../examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "../../examples/Charts/GradientLineChart.vue";
// import ReportsDoughnutChart from "@/examples/Charts/ReportsDoughnutChart.vue";
import PieChart from "@/examples/Charts/PieChart.vue";
// import SalesTable from "./components/SalesTable.vue";
import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import DatePicker from "../../components/DatePicker/DatePicker.vue";
import moment from "moment";

import {
  getSiteData,
  getConsumptionStatsData,
  getProductionStatsData,
  getDropDownData,
  getOnOffTrackData,
  getLocations,
  getLifetimeProduction,
  getAmcData,
} from "../../services/api.service";

// import Globe from "../../examples/Globe.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { getValueWithSuffix } from "../../utils";

export default {
  name: "GeneralDashboard",
  components: {
    // MiniStatisticsCard,
    // ReportsBarChart,
    ProgressLineChart,
    PlayerCard,
    // DefaultLineChart,
    // GradientLineChart,
    // SalesTable,
    // ReportsDoughnutChart,
    // Globe,
    PieChart,
    DatePicker,
  },
  data() {
    return {
      faHandPointer,
      faUsers,
      faCreditCard,
      faScrewdriverWrench,
      iconBackground: "bg-gradient-success",
      US,
      DE,
      BR,
      GB,
      dropdownItems: {
        dateRange: {
          name: "Date range",
          options: [
            "Today",
            "Yesterday",
            "Last Week",
            "Last Month",
            "All Time",
          ],
        },
        discom: { name: "Discom", options: ["ALL"] },
        states: {
          name: "States",
          options: ["ALL"],
        },
        assetType: { name: "Asset type", options: ["ALL"] },
      },
      selected: {
        discom: "ALL",
        assetType: "ALL",
        dateRange: "Last Week",
        states: "ALL",
        amc: "ALL",
      },
      selectedItems: ["Date range", "Discom", "States", "Asset type"],
      openDropdown: null,
      onlinePieStats: {
        labels: [],
        label: "",
        data: [],
        total: 0,
      },
      operationalPieStats: {
        labels: [],
        label: "",
        data: [],
        total: 0,
      },
      productionStats: {
        title: "",
        count: 0,
        data: {
          labels: [],
          data: [],
        },
        unitValue: "",
      },
      consumptionStats: {
        title: "",
        count: 0,
        data: {
          labels: [],
          data: [],
        },
        unitValue: "",
      },
      locationStats: [],
      onlineStats: {
        title: "",
        count: 0,
        data: {
          labels: [],
          data: [],
        },
      },
      lifetimeProduction: getValueWithSuffix(0, "Wh"),
      picker: {
        show: {
          calendar: false,
        },
        todayDt: moment(),
        firstDt: moment().subtract(8, "M"),
      },
      selectedDate: [
        moment().subtract(7, "days").startOf("day"),
        moment().endOf("day"),
      ],
      amcStats: {
        total: 0,
        totalCost: 0,
      },
    };
  },
  watch: {
    selectedDate(newDate, oldDate) {
      if (
        newDate[0].format("YYYY-MM-DD") !== oldDate[0].format("YYYY-MM-DD") ||
        newDate[1].format("YYYY-MM-DD") !== oldDate[1].format("YYYY-MM-DD")
      ) {
        this.getStats();
        this.getOnlineStats();
      }
    },
  },

  mounted: async function () {
    await this.getPieStats();
    await this.getdropDownOptions();
    await this.getStats();
    await this.getOnlineStats();
    await this.getMapLocations();
  },
  methods: {
    closeDropDownAndCalender() {
      if (this.openDropdown !== null) {
        this.openDropdown = null;
      }
      if (this.picker.show.calendar !== false) {
        this.picker.show.calendar = false;
      }
    },
    toggleDropdown(index) {
      this.openDropdown = this.openDropdown === index ? null : index;
    },
    selectOption(index, option, key) {
      this.selectedItems[index] = option;
      this.selected[key] = option;
      this.openDropdown = null;
      if (key === "dateRange") {
        this.showCalendar();
      }
      if (key === "dateRange") {
        this.manageDateRange(option);
      } else {
        this.getPieStats();
        this.getMapLocations();
      }
      this.getStats();
      this.getOnlineStats();
    },
    async getPieStats() {
      const { discom, assetType, states } = this.selected;
      const { statusData, operationalData } = await getSiteData(
        discom,
        assetType,
        states,

      );
      this.onlinePieStats = statusData;
      this.operationalPieStats = operationalData;
    },
    showCalendar() {
      this.picker.show.calendar = !this.picker.show.calendar;
      if (this.picker.show.calendar === false) {
        this.openDropdown = null;
      }
    },
    manageDateRange(option) {
      switch (option) {
        case "Today":
          this.selectedDate[0] = this.selectedDate[1] = moment();
          break;
        case "Yesterday":
          this.selectedDate[0] = this.selectedDate[1] = moment().subtract(
            1,
            "days"
          );

          break;
        case "Last Week":
          this.selectedDate[0] = moment().subtract(7, "days");
          this.selectedDate[1] = moment().endOf("day");

          break;
        case "Last Month":
          this.selectedDate[0] = moment().subtract(1, "months");
          this.selectedDate[1] = moment().endOf("day");

          break;
        default:
          this.selectedDate[0] = -1;
          this.selectedDate[1] = -1;
          break;
      }
    },
    async getStats() {
      const { discom, assetType, dateRange, states, amc } = this.selected;
      const lifetimeProduction = await getLifetimeProduction();
      this.lifetimeProduction = getValueWithSuffix(lifetimeProduction, "Wh");
      this.amcStats = await getAmcData();
      const productionStats =
        dateRange === "All Time"
          ? await getProductionStatsData(-1, -1, discom, assetType, states, amc)
          : await getProductionStatsData(
            this.selectedDate[0].format("YYYY-MM-DD"),
            this.selectedDate[1].format("YYYY-MM-DD"),
            discom,
            assetType,
            states,
            amc
          );
      this.productionStats = {
        title: `${productionStats.count}/${productionStats.total} sites monitored`,
        count: productionStats.totalPower,
        data: {
          labels: productionStats.labels,
          data: [productionStats.data],
        },
        unitValue: getValueWithSuffix(productionStats.totalPower),
      };
      const consumptionStats =
        dateRange === "All Time"
          ? await getConsumptionStatsData(
            -1,
            -1,
            discom,
            assetType,
            states,
            amc
          )
          : await getConsumptionStatsData(
            this.selectedDate[0].format("YYYY-MM-DD"),
            this.selectedDate[1].format("YYYY-MM-DD"),
            discom,
            assetType,
            states,
            amc
          );
      this.consumptionStats = {
        title: `${consumptionStats.count}/${consumptionStats.total} sites monitored`,
        count: consumptionStats.totalEnergy,
        data: {
          labels: consumptionStats.labels,
          data: [consumptionStats.data],
        },
        unitValue: getValueWithSuffix(consumptionStats.totalEnergy, "Wh"),
      };
    },
    async getOnlineStats() {
      const { discom, assetType, dateRange, states, amc } = this.selected;
      const stats =
        dateRange === "All Time"
          ? await getOnOffTrackData(-1, -1, discom, assetType, states, amc)
          : await getOnOffTrackData(
            this.selectedDate[0].format("YYYY-MM-DD"),
            this.selectedDate[1].format("YYYY-MM-DD"),
            discom,
            assetType,
            states,
            amc
          );
      const { graph } = stats;
      this.onlineStats = {
        title: "Online/Offline Ratio",
        count: 0,
        data: {
          labels: graph.labels,
          data: [graph.data.online, graph.data.offline],
        },
      };
    },
    async getdropDownOptions() {
      const { discom, assetType, state } = await getDropDownData();
      this.dropdownItems.discom.options = discom;
      this.dropdownItems.assetType.options = assetType;
      this.dropdownItems.states.options = state;
    },
    async getMapLocations() {
      const { discom, assetType, states, amc } = this.selected;
      const stats = await getLocations(discom, assetType, states, amc);
      this.locationStats = stats.data;
    },
  },
};
</script>
<style lang="scss">
.dropdownmenu {
  position: relative;
  margin-bottom: 10px;
}

.dropdown-toggle {
  padding: 10px 31px;
  width: 249px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  color: black;
  background: white;
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
}

.filter_section {
  .dropdown-toggle::after {
    right: 16px !important;
    position: absolute !important;
    top: 43% !important;
  }

  .menu-dropdown {
    width: 100%;
    border-radius: 5px;
    padding: 0 10px 10px;

    div.option_menu {
      margin-top: 10px;
      cursor: pointer;
    }
  }
}

.dropdown-daterange {
  padding: 11px 15px;
  width: 249px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  color: black;
  background: white;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
}

.calander_picker {
  position: absolute;
  z-index: 9;
  left: 15px;
  top: 100%;
  background: white;

  .g-calendar .calendar-container .month-name {
    padding: 18px;
    font-size: 17px;
    display: flex;
    align-items: center;
  }

  .g-calendar .calendar-container .prev-icon {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid black;
  }

  .g-calendar .calendar-container .next-icon {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
  }

  .g-calendar .calendar-container .day-name span {
    padding: 10px;
    color: black;
  }
}

.section_title {
  color: #0B335B;
  font-size: 25px;
  text-wrap: nowrap;
}

.g-calendar .calendar-container .calendar-dates .date-row .blank-day.date-highlighted,
.g-calendar .calendar-container .calendar-dates .date-row .date.date-highlighted {
  background: #dfeeff !important;
}

.g-calendar .calendar-container .calendar-dates .date-row .blank-day.date-selected,
.g-calendar .calendar-container .calendar-dates .date-row .date.date-selected {
  background: #0B335B !important;
}

.calendar-actions button {
  margin-right: 12px;
}

.count {
  text-transform: uppercase;
  font-size: 36px;
  text-align: center;
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    opacity: 0.8;
    margin-top: 11px;
  }
}

.counsumption_info {
  .count {
    display: none;
  }
}

.chart-row {
  min-height: 35vh;
}

.chart-btn {
  margin-bottom: 0rem;
  padding: 0px 0px 0px 0px;
}

.stats-bullet {
  list-style: none;
  display: grid;
  justify-content: space-around;
  grid-template-columns: 50% 60%;
  width: max-content;
  margin: 2px;
}

.stats-bullet li {
  margin: 0px 10px;
}

.stats-bullet li::before {
  content: "\2022";
  color: #0B335B;
}
</style>
