<template>
  <div class="pb-5 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="filter_section">
          <!-- <h2 class="mb-3 dashboard_title">Filters</h2> -->
          <div class="d-flex justify-content-between mt-4 flex-wrap">
            <div v-for="(value, key, index) in dropdownItems" :key="key" class="dropdownmenu">
              <div v-if="index === 0">
                <div class="dropdown-daterange" style="cursor: pointer" @click="showCalendar()">
                  <div v-if="selected['dateRange'] === 'All Time'">All Time</div>
                  <div v-else>
                    {{ selectedDate[0].format("YYYY-MM-DD") }} -
                    {{ selectedDate[1].format("YYYY-MM-DD") }}
                  </div>
                  <i class="fa fa-calendar"></i>
                </div>
                <div v-show="picker.show.calendar" class="row mt-1 border calander_picker"
                  :onmouseleave="closeDropDownAndCalender">
                  <date-picker v-model="selectedDate" :options="value.options" :first-month="picker.firstDt"
                    :last-month="picker.todayDt" :enable-second-calendar="true" :next-prev-icon="true"
                    @callSelected="selectOption" @onSelect="showCalendar" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-plain">
          <div class="py-3 px-0 card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-wrap">
                  <h2 class="mb-0 font-weight-bolder section_title w-100">
                    Site OverView
                  </h2>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="card mt-4">
                  <div v-if="lifetimeProduction" class="mt-1 mb-3 w-100" style="font-size: 16px; font-weight: bold">
                    <div v-if="siteData" class="job-card-info border-0 pt-4" >
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row mb-3">
                            <div class="col-lg-4">
                              <label>
                                <!-- <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="12" height="17"
                                  viewBox="0 0 12 17" fill="none">
                                  <path
                                    d="M12 5.95C12 5.16863 11.8448 4.39492 11.5433 3.67303C11.2417 2.95115 10.7998 2.29522 10.2426 1.74271C9.68549 1.19021 9.02405 0.751932 8.2961 0.452917C7.56815 0.153901 6.78793 0 6 0C5.21207 0 4.43185 0.153901 3.7039 0.452917C2.97595 0.751932 2.31451 1.19021 1.75736 1.74271C1.20021 2.29522 0.758251 2.95115 0.456723 3.67303C0.155194 4.39492 -1.17411e-08 5.16863 0 5.95C0 7.12895 0.350571 8.22545 0.947143 9.15025H0.940286L6 17L11.0597 9.15025H11.0537C11.6716 8.19534 12 7.08459 12 5.95ZM6 8.5C5.31801 8.5 4.66396 8.23134 4.18173 7.75312C3.69949 7.27491 3.42857 6.6263 3.42857 5.95C3.42857 5.2737 3.69949 4.6251 4.18173 4.14688C4.66396 3.66866 5.31801 3.4 6 3.4C6.68199 3.4 7.33604 3.66866 7.81827 4.14688C8.30051 4.6251 8.57143 5.2737 8.57143 5.95C8.57143 6.6263 8.30051 7.27491 7.81827 7.75312C7.33604 8.23134 6.68199 8.5 6 8.5Z"
                                    fill="#0471D8" />
                                </svg> -->
                                Name</label>
                            </div>
                            <div class="col-lg-8">
                              <span>
                                {{ siteData.name }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="row mb-3">
                            <div class="col-lg-4">
                              <label>
                                <!-- <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="12" height="17"
                                  viewBox="0 0 12 17" fill="none">
                                  <path
                                    d="M12 5.95C12 5.16863 11.8448 4.39492 11.5433 3.67303C11.2417 2.95115 10.7998 2.29522 10.2426 1.74271C9.68549 1.19021 9.02405 0.751932 8.2961 0.452917C7.56815 0.153901 6.78793 0 6 0C5.21207 0 4.43185 0.153901 3.7039 0.452917C2.97595 0.751932 2.31451 1.19021 1.75736 1.74271C1.20021 2.29522 0.758251 2.95115 0.456723 3.67303C0.155194 4.39492 -1.17411e-08 5.16863 0 5.95C0 7.12895 0.350571 8.22545 0.947143 9.15025H0.940286L6 17L11.0597 9.15025H11.0537C11.6716 8.19534 12 7.08459 12 5.95ZM6 8.5C5.31801 8.5 4.66396 8.23134 4.18173 7.75312C3.69949 7.27491 3.42857 6.6263 3.42857 5.95C3.42857 5.2737 3.69949 4.6251 4.18173 4.14688C4.66396 3.66866 5.31801 3.4 6 3.4C6.68199 3.4 7.33604 3.66866 7.81827 4.14688C8.30051 4.6251 8.57143 5.2737 8.57143 5.95C8.57143 6.6263 8.30051 7.27491 7.81827 7.75312C7.33604 8.23134 6.68199 8.5 6 8.5Z"
                                    fill="#0471D8" />
                                </svg> -->
                                Kind</label>
                            </div>
                            <div class="col-lg-8">
                              <span>
                                {{ siteData.kind }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row mb-3">
                            <div class="col-lg-4">
                              <label>
                                Capacity</label>
                            </div>
                            <div class="col-lg-8">
                              <span>
                                {{ siteData.capacity }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="row mb-3">
                            <div class="col-lg-4">
                              <label>
                                All Time Generation</label>
                            </div>
                            <div class="col-lg-8">
                              <span>
                                {{ lifetimeProduction }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="devices" class="row" >
          <div  v-for="(device, index) in devices" :key="index" class="col-lg-6 col-sm-6" >
            <div class="card h-100 z-index-2">
              <div>
                <div class="p-3 card-body chart-row row">
                  <div class="card_title_row col-lg-12">
                    <div class="image_container">
                      <img class="shadow border-radius-lg" :src="device.picture" alt="Image description" />
                    </div>
                    <!-- <h6 class="cardHeader">Device {{ index + 1 }}</h6> -->
                  </div>
                  <div class="col-lg-12">
                  <div class="row">
                    <div v-for="(value, key) in device" :key="key" class="col-lg-6" >
                      <div class="row">
                        <div v-if="key !== 'picture'" class="col-lg-6" >
                          <label class="job-cardlabel">{{ key }}</label>
                        </div>
                        <div  v-if="key !== 'picture'" class="col-lg-6" >
                          <span class="job-cardspan">{{ value }}</span>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row  mt-4">
          <div class="col-lg-3 col-sm-5">
            <div class="card z-index-2">
              <div class="">

                <div class="p-3 card-body chart-row">
                  <div class="card_title_row d-flex justify-content-between">
                    <h6 class="cardHeader">Total Power Generated</h6>
                  </div>
                  <progress-line-chart id="progress-line-chart-component" :count="productionStats.count"
                    :stats="productionStats.data" :display-ticks="false" :unit-value="productionStats.unitValue" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-5">
            <div class="card z-index-2">
              <div class="p-3 card-body chart-row">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Online/Offline ratio</h6>
                </div>

                <div>
                  <pie-chart id="pie-chart-component" height="160" :stats="onlinePieStats" :selected-items="selected"
                    title="status" show-btns="false" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-5">
            <div class="card z-index-2">
              <div class="">

                <div class="p-3 card-body chart-row">
                  <div class="card_title_row d-flex justify-content-between">
                    <h6 class="cardHeader">Total Power Consumed</h6>
                  </div>
                  <progress-line-chart id="progress-line-chart-component" :count="consumptionStats.count"
                    :stats="consumptionStats.data" :display-ticks="false" :unit-value="consumptionStats.unitValue"
                    unit="Wh" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-5">
            <div class="card z-index-2">
              <div class="p-3 card-body chart-row">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Operational Status</h6>
                </div>
                <div>
                  <pie-chart id="operational-pie-chart" height="160" :stats="operationalPieStats"
                    :selected-items="selected" title="operationalStatus" show-btns="false" />
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-3">

          <div class="col-lg-12 mt-3">
            <div class="card z-index-2">
              <div class="p-3 card-body counsumption_info ">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Generation - ( {{ selectedDate[0].format("YYYY-MM-DD") }} -
                    {{ selectedDate[1].format("YYYY-MM-DD") }})</h6>
                </div>
                <progress-line-chart id="progress-line-chart-component" :count="productionStats.count"
                  :stats="productionStats.data" />
              </div>
            </div>
          </div>

          <div class="col-lg-12 mt-3">
            <div class="card z-index-2">
              <div class="p-3 card-body counsumption_info ">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Online/Offline Ratio</h6>
                </div>
                <progress-line-chart id="progress-line-chart-component" :count="onlineStats.count"
                  :stats="onlineStats.data" :convert-units="false" />
              </div>
            </div>
          </div>

          <div class="col-lg-12 mt-3">
            <div class="card z-index-2">
              <div class="p-3 card-body counsumption_info ">
                <div class="card_title_row d-flex justify-content-between">
                  <h6 class="cardHeader">Consumption Info</h6>
                </div>
                <progress-line-chart id="progress-line-chart-component" :count="consumptionStats.count"
                  :stats="consumptionStats.data" unit="Wh" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-3">
            <player-card :map-locations="locationStats" />
          </div>
          <div v-if="loading" class="loader">
            <div
              v-for="(_, loadingIndex) in Array(5)"
              :key="loadingIndex"
              class="spinner-grow text-dark"
              role="status"
            ></div>
          </div>
          <div v-if="!loading" class="data">
            <div v-if="listLoading" class="inner-loader">
              <div
                v-for="(_, loadingIndex) in Array(5)"
                :key="loadingIndex"
                class="spinner-grow text-dark"
                role="status"
              ></div>
            </div>
            <div v-if="!listLoading" class="mt-4 row">
              <div class="col-12">
                <div class="card p-3">
                  <h2 class="mb-0 font-weight-bolder section_title">
                    AMC For This Site
                  </h2>
                  <div class="table-responsive">
                    <table  id="amcsites" class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>Site</th>
                          <th>Status</th>
                          <th>Job Type</th>
                          <th>Service Date</th>
                          <th>Assigned</th>
                        </tr>
                      </thead>
                      <tbody v-if="amcList.length>0">
                        <tr v-for="(amc,idx) in amcList" :key="idx">
                          <td class="text-sm font-weight-normal site-btn btn-link">
                            <router-link
                              :to="{
                                name: 'AMC Job Card Detail',
                                query: {
                                  jobId: amc.jobId ,
                                },
                              }"
                            >
                              {{ amc.siteName }}
                            </router-link>
                          </td>
                          <td class="text-sm font-weight-normal"
                            :style="{
                              color: getColor(amc.status)
                            }"
                          
                          >
                            {{ amc.status}}
                          </td>
                          <td class="text-sm font-weight-normal">{{ amc.jobType}}</td>
                          <td class="text-sm font-weight-normal">
                            {{ amc.serviceDate}}
                          </td>
                          <td
                            class="text-sm"
                          >
                            {{amc.serviceBy}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import ProgressLineChart from "../pages/projects/components/ProgressLineChart.vue";
// import DefaultLineChart from "../../examples/Charts/DefaultLineChart.vue";
import PlayerCard from "./components/PlayerCard.vue";
// import ReportsBarChart from "../../examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "../../examples/Charts/GradientLineChart.vue";
// import ReportsDoughnutChart from "@/examples/Charts/ReportsDoughnutChart.vue";
import PieChart from "@/examples/Charts/PieChart.vue";
// import SalesTable from "./components/SalesTable.vue";
import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import DatePicker from "../../components/DatePicker/DatePicker.vue";
import moment from "moment";

import {
  getConsumptionStatsData,
  getProductionStatsData,
  // getDropDownData,
  getOnOffTrackData,
  getLocations,
  getSiteIdData,
  getAmcList
} from "../../services/api.service";

// import Globe from "../../examples/Globe.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { getValueWithSuffix } from "../../utils";
import { DataTable } from "simple-datatables";
export default {
  name: "SiteDetails",
  components: {
    // MiniStatisticsCard,
    // ReportsBarChart,
    ProgressLineChart,
    PlayerCard,
    // DefaultLineChart,
    // GradientLineChart,
    // SalesTable,
    // ReportsDoughnutChart,
    // Globe,
    PieChart,
    DatePicker,
  },
  data() {
    return {
      dataTable: null,
      faHandPointer,
      faUsers,
      faCreditCard,
      faScrewdriverWrench,
      iconBackground: "bg-gradient-success",
      US,
      DE,
      BR,
      GB,
      dropdownItems: {
        dateRange: {
          name: "Date range",
          options: ["Today", "Yesterday", "Last Week", "Last Month", "All Time"],
        }
      },
      siteId: "",
      loading:false,
      amcList:[],
      siteIdArr:[],
      siteData: null,
      devices: null,
      // selectedDevice: 1,
      selected: {
        discom: "ALL",
        assetType: "ALL",
        dateRange: "Last Week",
        states: "ALL",
        amc: "ALL",
        gridLink: "ALL",

      },
      listLoading:false,
      onlinePieStats: {
        labels: [],
        label: "",
        data: [],
        total: 0,
      },
      operationalPieStats: {
        labels: [],
        label: "",
        data: [],
        total: 0,
      },
      productionStats: {
        title: "",
        count: 0,
        data: {
          labels: [],
          data: [],
        },
        unitValue: "",
      },
      consumptionStats: {
        title: "",
        count: 0,
        data: {
          labels: [],
          data: [],
        },
        unitValue: "",
      },
      locationStats: [],
      onlineStats: {
        title: "",
        count: 0,
        data: {
          labels: [],
          data: [],
        },
      },
      lifetimeProduction: getValueWithSuffix(0, "Wh"),
      picker: {
        show: {
          calendar: false,
        },
        todayDt: moment(),
        firstDt: moment().subtract(8, "M"),
      },
      selectedDate: [
        moment().subtract(7, "days").startOf("day"),
        moment().endOf("day"),
      ],
    };
  },

  watch: {
    selectedDate(newDate, oldDate) {
      if (
        newDate[0].format("YYYY-MM-DD") !== oldDate[0].format("YYYY-MM-DD") ||
        newDate[1].format("YYYY-MM-DD") !== oldDate[1].format("YYYY-MM-DD")
      ) {
        this.getStats();
        this.getOnlineStats();
      }
    },
  },

  mounted: async function () {
    const query = this.$router.currentRoute.value.query;
    this.siteId = query['siteId'];
    this.lifetimeProduction = query['lifeTime'];
    this.loading=true;
    await this.getStats();
    await this.siteIdData();
    await this.getOnlineStats();
    await this.getMapLocations();
    this.siteIdArr.push(this.siteData.adminId)
    if(this.siteData.adminId===""){
      this.loading=false;
    }
    this.amcList=await getAmcList(this.siteIdArr)
    this.loading=false;
  },
  updated: function () {
    if (document.getElementById("amcsites")) {
      this.dataTable = new DataTable("#amcsites", {
        searchable: true,
        fixedHeight: true,
      });
    }
  },
  methods: {
    async siteIdData() {
      const siteDetails = await getSiteIdData(this.siteId);
      siteDetails.kind = this.updateKind(siteDetails.kind);
      siteDetails.name = this.updateName(siteDetails.name);
      siteDetails.capacity = this.updateCapacity(siteDetails.capacity);
      this.devices = siteDetails.devices;
      this.siteData = siteDetails;
    },
    getColor(status){
    if(status=="COMPLETED"){
    return 'green';
    }
    if(status=="HOLD"){
    return 'orange';
    }
    if(status=="ASSIGNED"){
    return 'blue';
    }
    if(status=="PENDING"){
    return 'red';
    }
    if(status=="ISSUE"){
    return 'red';
    }
    },
    updateKind(kind) {
      return kind.charAt(0).toUpperCase() + kind.slice(1).toLowerCase();
    },
    updateName(fullName) {
      return fullName.split("-")[0].trim();
    },
    updateCapacity(capacity) {
      return capacity + "KW";
    },
    closeDropDownAndCalender() {
      if (this.picker.show.calendar !== false) {
        this.picker.show.calendar = false;
      }
    },

    selectOption(index, option, key) {
      this.selected[key] = option;
      this.showCalendar();
      this.manageDateRange(option);
      this.getStats();
      this.getOnlineStats();
    },
    showCalendar() {
      this.picker.show.calendar = !this.picker.show.calendar;
    },
    manageDateRange(option) {
      switch (option) {
        case "Today":
          this.selectedDate[0] = this.selectedDate[1] = moment();
          break;
        case "Yesterday":
          this.selectedDate[0] = this.selectedDate[1] = moment().subtract(
            1,
            "days"
          );
          break;
        case "Last Week":
          this.selectedDate[0] = moment().subtract(7, "days");
          this.selectedDate[1] = moment().endOf("day");

          break;
        case "Last Month":
          this.selectedDate[0] = moment().subtract(1, "months");
          this.selectedDate[1] = moment().endOf("day");

          break;
        default:
          this.selectedDate[0] = -1;
          this.selectedDate[1] = -1;
          break;
      }
    },
    async getStats() {
      const { discom, assetType, dateRange, states, amc } = this.selected;
      const productionStats =
        dateRange === "All Time"
          ? await getProductionStatsData(-1, -1, discom, assetType, states, amc, this.siteId)
          : await getProductionStatsData(
            this.selectedDate[0].format("YYYY-MM-DD"),
            this.selectedDate[1].format("YYYY-MM-DD"),
            discom,
            assetType,
            states,
            amc,
            this.siteId
          );
      this.productionStats = {
        title: `${productionStats.count}/${productionStats.total} sites monitored`,
        count: productionStats.totalPower,
        data: {
          labels: productionStats.labels,
          data: [productionStats.data],
        },
        unitValue: getValueWithSuffix(productionStats.totalPower),
      };
      const consumptionStats =
        dateRange === "All Time"
          ? await getConsumptionStatsData(-1, -1, discom, assetType, states, amc, this.siteId)
          : await getConsumptionStatsData(
            this.selectedDate[0].format("YYYY-MM-DD"),
            this.selectedDate[1].format("YYYY-MM-DD"),
            discom,
            assetType,
            states,
            amc,
            this.siteId
          );
      this.consumptionStats = {
        title: `${consumptionStats.count}/${consumptionStats.total} sites monitored`,
        count: consumptionStats.totalEnergy,
        data: {
          labels: consumptionStats.labels,
          data: [consumptionStats.data],
        },
        unitValue: getValueWithSuffix(consumptionStats.totalEnergy, "Wh"),
      };
    },
    async getOnlineStats() {
      const { discom, assetType, dateRange, states, amc } = this.selected;
      const stats =
        dateRange === "All Time"
          ? await getOnOffTrackData(-1, -1, discom, assetType, states, amc, this.siteId)
          : await getOnOffTrackData(
            this.selectedDate[0].format("YYYY-MM-DD"),
            this.selectedDate[1].format("YYYY-MM-DD"),
            discom,
            assetType,
            states,
            amc,
            this.siteId
          );
      const { graph, pie } = stats;
      this.onlineStats = {
        title: "Online/Offline Ratio",
        count: 0,
        data: {
          labels: graph.labels,
          data: [graph.data.online, graph.data.offline],
        },
      };
      this.onlinePieStats = pie.status;
      this.operationalPieStats = pie.operationalStatus
    },
    async getMapLocations() {
      const { discom, assetType, states, amc } = this.selected;
      const stats = await getLocations(discom, assetType, states, amc, this.siteId);
      this.locationStats = stats.data;
    },
  },
};
</script>
<style lang="scss">
.dropdownmenu {
  position: relative;
  margin-bottom: 10px;
}

.dropdown-toggle {
  padding: 10px 31px;
  width: 249px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  color: black;
  background: white;
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
}

.filter_section {
  .dropdown-toggle::after {
    right: 16px !important;
    position: absolute !important;
    top: 43% !important;
  }

  .menu-dropdown {
    width: 100%;
    border-radius: 5px;
    padding: 0 10px 10px;

    div.option_menu {
      margin-top: 10px;
      cursor: pointer;
    }
  }
}

.dropdown-daterange {
  padding: 11px 15px;
  width: 249px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  color: black;
  background: white;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
}

.calander_picker {
  position: absolute;
  z-index: 9;
  left: 15px;
  top: 100%;
  background: white;

  .g-calendar .calendar-container .month-name {
    padding: 18px;
    font-size: 17px;
    display: flex;
    align-items: center;
  }

  .g-calendar .calendar-container .prev-icon {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid black;
  }

  .g-calendar .calendar-container .next-icon {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
  }

  .g-calendar .calendar-container .day-name span {
    padding: 10px;
    color: black;
  }
}

.section_title {
  color: #0B335B;
  font-size: 25px;
  text-wrap: nowrap;
}

.g-calendar .calendar-container .calendar-dates .date-row .blank-day.date-highlighted,
.g-calendar .calendar-container .calendar-dates .date-row .date.date-highlighted {
  background: #dfeeff !important;
}

.g-calendar .calendar-container .calendar-dates .date-row .blank-day.date-selected,
.g-calendar .calendar-container .calendar-dates .date-row .date.date-selected {
  background: #0B335B !important;
}

.calendar-actions button {
  margin-right: 12px;
}

.count {
  text-transform: uppercase;
  font-size: 36px;
  text-align: center;
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    opacity: 0.8;
    margin-top: 11px;
  }
}

.counsumption_info {
  .count {
    display: none;
  }
}

.chart-row {
  min-height: 35vh;
}

.chart-btn {
  margin-bottom: 0rem;
  padding: 0px 0px 0px 0px;
}

.stats-bullet {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: max-content;
  margin: 2px;
}

.stats-bullet li {
  text-align: center;
  margin: 0px 10px;
}

.stats-bullet li::before {
  content: "\2022";
  color: #0B335B;
}

.job-cardlabel {
  color: #0F0F0F;
  font-size: 12px;
  font-weight: 600;
  margin-left: 0;
  text-transform: capitalize;
}

.job-cardspan {
  color: #0F0F0F;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
}

.image_container {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin-bottom: 10px;
  }
}

.device-data-table .dataTable-sorter::before,
.device-data-table .dataTable-sorter::after {
  display: none !important;
}
</style>
