<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <!-- <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        /> -->
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your username and password to sign in</p>
                </div>
                <div class="card-body">
                  <!-- <form role="form"> -->
                  <div class="mb-3">
                    <div class="form-group">
                      <input id="username" v-model="username" type="text" placeholder="Username" name="username"
                        class="form-control" size="lg" autocomplete="off" @keyup.enter="handleEnterKey"/>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-group">
                      <input id="password" v-model="password" type="password" placeholder="Password" name="password"
                        class="form-control" size="lg" autocomplete="off" ref="passwordField" @keyup.enter="login"/>
                    </div>
                  </div>
                  <!-- <soft-switch id="rememberMe" name="rememberMe">
                      Remember me
                    </soft-switch> -->
                  <div class="text-center" style="color: red">{{ error }}</div>

                  <div class="text-center">
                    <soft-button class="mt-4" :loading="loading" variant="gradient" color="success" full-width size="lg" @click="login">Sign
                      in
                    </soft-button>
                  </div>
                  <!-- </form> -->
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Signup Illustration' }"
                      class="text-success text-gradient font-weight-bold"
                    >
                      Sign up
                    </router-link>
                  </p>
                </div> -->
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="m-3 position-relative background-image-color-theme h-100 px-7 border-radius-lg d-flex flex-column justify-content-center">
                <img src="@/assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                  class="position-absolute opacity-4 start-0" />
                <div class="position-relative">
                  <img class="max-width-400 w-100 position-relative z-index-2"
                    src="@/assets/img/illustrations/trillectric.png" alt="chat-img" />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  "Centralize, Optimize, Revolutionize"
                </h4>
                <p class="text-white">
                  Simplifying Distributed Energy Management for a Modern and Efficient Grid
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import { login } from '../../../services/api.service'
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "SigninIllustration",
  components: {
    //  Navbar,
    // SoftInput,
    //  SoftSwitch,
    SoftButton,
  },
  data() {
    return {
      username: "",
      password: "",
      error: "",
      loading: false
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeMount() {
    console.log('Fetching Token............', localStorage.getItem('token'));
    if(localStorage.getItem('token')){
      this.$router.push({ name: "General Dashboard" });
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    handleEnterKey(event) {
    if (event.key === 'Enter') {
      this.$refs.passwordField.focus();
    }
  },
    async login() {
      this.loading = true;
      this.error = "";
      if (this.username.length && this.password.length) {
        const result = await login({ username: this.username.toLowerCase(), password: this.password });
        this.loading = false;
        if (!result) {
          this.error = "Invalid credentials.";
        } else {
          this.$router.push({ name: "General Dashboard" });
        }
      } else {
        this.error = "Username And Password are required!"
        this.loading = false;
      }
    }
  },
};
</script>
<style lang="scss">
.background-image-color-theme{
  background: linear-gradient(to right, #0B335B, #0B1B2B)!important;
}
</style>
