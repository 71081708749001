<template>
  <div v-if="loading" class="loader">
    <div
      v-for="(_, loadingIndex) in Array(5)"
      :key="loadingIndex"
      class="spinner-grow text-dark"
      role="status"
    ></div>
  </div>
  <div v-if="!loading" class="data">
    <div class="filter-row">
      <div v-for="(value, key) in filter" :key="key" class="filter-col">
        <label :for="key">{{ value.key }}:</label>
        <select :id="key" v-model="value.selected" class="filter-dropdown">
          <option v-for="item in value.options" :key="item">{{ item }}</option>
        </select>
      </div>
    </div>
    <div v-if="listLoading" class="inner-loader">
      <div
        v-for="(_, loadingIndex) in Array(5)"
        :key="loadingIndex"
        class="spinner-grow text-dark"
        role="status"
      ></div>
    </div>
    <div v-if="!listLoading" class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="table-responsive">
            <table id="sites" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Asset Type</th>
                  <th>Discom</th>
                  <th>Capacity</th>
                  <th>Status</th>
                  <th>Operational Status</th>
                  <th>Lifetime Production</th>
                  <th>AMC Start Date</th>
                  <th>AMC Renewal Date</th>
                  <th>AMC Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(site, siteIndex) in sites" :key="siteIndex">
                  <td class="text-sm font-weight-normal site-btn btn-link">
                    <router-link
                      :to="{
                        name: 'Site Details',
                        query: {
                          siteId: site._id,
                          lifeTime: site.lifetime,
                        },
                      }"
                    >
                      {{ site.name }}
                    </router-link>
                  </td>
                  <td class="text-sm font-weight-normal">
                    {{ site.assetType }}
                  </td>
                  <td class="text-sm font-weight-normal">{{ site.discom }}</td>
                  <td class="text-sm font-weight-normal">
                    {{ site.capacity ? `${site.capacity} kW` : "-" }}
                  </td>
                  <td
                    class="text-sm"
                    :style="{
                      color: site.status === 'ONLINE' ? 'green' : 'red',
                      fontWeight: site.status === 'ONLINE' ? 'normal' : 'bold',
                    }"
                  >
                    {{ site.status }}
                  </td>
                  <td
                    class="text-sm"
                    :style="{
                      color:
                        site.operationalStatus === 'NORMAL'
                          ? 'green'
                          : 'orange',
                      fontWeight:
                        site.operationalStatus === 'NORMAL' ? 'normal' : 'bold',
                    }"
                  >
                    {{ site.operationalStatus }}
                  </td>
                  <td class="text-sm font-weight-normal">
                    {{ site.lifetime }}
                  </td>
                  <td class="text-sm font-weight-normal">
                    {{ site.amcStartDate }}
                  </td>
                  <td class="text-sm font-weight-normal">
                    {{ site.amcRenewalDate }}
                  </td>
                  <td class="text-sm font-weight-normal">
                    {{ site.amcPrice }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import { getDropDownData, getSites } from "../../services/api.service";
export default {
  data() {
    return {
      loading: false,
      listLoading: false,
      sites: [],
      dataTable: null,
      filter: {
        discom: {
          key: "Discom",
          selected: "ALL",
          options: [],
        },
        assetType: {
          key: "AssetType",
          selected: "ALL",
          options: [],
        },
        gridLink: {
          key: "GridLink",
          selected: "ALL",
          options: ["ALL"],
        },
        status: {
          key: "Status",
          selected: "ALL",
          options: [],
        },
        operationalStatus: {
          key: "OperationalStatus",
          selected: "ALL",
          options: [],
        },
        state: {
          key: "State",
          selected: "ALL",
          options: [],
        },
        amc: {
          key: "AMC",
          selected: "ALL",
          options: ["ALL"],
        },
      },
    };
  },
  computed: {
    assetType() {
      return this.filter.assetType.selected;
    },
    discom() {
      return this.filter.discom.selected;
    },
    operationalStatus() {
      return this.filter.operationalStatus.selected;
    },
    status() {
      return this.filter.status.selected;
    },
    state() {
      return this.filter.state.selected;
    },
    amc() {
      return this.filter.amc.selected;
    },
    gridLink() {
      return this.filter.gridLink.selected;
    },
  },
  watch: {
    assetType: function () {
      this.filterSites();
    },
    discom: function () {
      this.filterSites();
    },
    operationalStatus: function () {
      this.filterSites();
    },
    status: function () {
      this.filterSites();
    },
    state: function () {
      this.filterSites();
    },
    amc: function () {
      this.filterSites();
    },
    gridLink: function () {
      this.filterSites();
    },
  },
  mounted: async function () {
    this.loading = true;
    const filters = await getDropDownData();
    Object.keys(filters).forEach((key) => {
      this.filter[key].options = filters[key];
    });
    const query = this.$router.currentRoute.value.query;
    Object.keys(query).forEach((key) => {
      this.filter[key].selected = query[key];
    });
    this.sites = await getSites(
      this.filter.discom.selected,
      this.filter.assetType.selected,
      this.filter.status.selected,
      this.filter.operationalStatus.selected,
      this.filter.state.selected,
      this.filter.amc.selected,
      this.filter.gridLink.selected
    );
    this.loading = false;
  },
  updated: function () {
    if (document.getElementById("sites")) {
      this.dataTable = new DataTable("#sites", {
        searchable: true,
        fixedHeight: true,
      });
    }
  },
  methods: {
    async filterSites() {
      if (!this.loading) {
        this.listLoading = true;
        this.dataTable.destroy();
        this.sites = await getSites(
          this.filter.discom.selected,
          this.filter.assetType.selected,
          this.filter.status.selected,
          this.filter.operationalStatus.selected,
          this.filter.state.selected,
          this.filter.amc.selected,
          this.filter.gridLink.selected
        );
        this.listLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.inner-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 90vh;
}

.filter-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-col {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  label {
    margin-right: 0.625rem;
  }

  .filter-dropdown {
    padding: 0.5rem;
    border: 0.063rem solid #ddd;
    border-radius: 0.25rem;
  }
}
.site-btn {
  cursor: pointer;
}
</style>