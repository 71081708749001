<template>
  <div class="overflow-hidden">
    <div class="p-2 pb-0 ps-0 card-header pe-0">
      <div v-if="count !== -1">
        <div class="d-flex align-items-center justify-content-center">
          <!-- <div class="text-center shadow icon icon-shape border-radius-md bg-gradient-custom">
            <i class="text-lg ni opacity-10" :class="`ni-${icon}`" aria-hidden="true"></i>
          </div> -->
          <div :class="isRTL ? 'me-3' : 'ms-0'">
            <p class="mb-0 text-sm text-capitalize font-weight-bold">
              {{ title }}
            </p>
            <h6 class=" value">
              {{ unitValue }}

            </h6>
          </div>

          <!-- <div class="progress-wrapper w-25" :class="isRTL ? 'me-auto' : 'ms-auto'">
          <div class="progress-info">
            <div class="progress-j">
              <span class="text-xs font-weight-bold">{{ progress }}%</span>
            </div>
          </div>
          <div class="progress">
            <div class="progress-bar bg-success" :class="`w-${progress}`" role="progressbar" :aria-valuenow="progress"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div> -->
        </div>
      </div>
    </div>
    <div class="p-0 mt-3 card-body">
      <div class="chart">
        <Line :id="id" :height="height" :chart-data="data" :chart-options="options" />
      </div>
    </div>
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import { mapState } from "vuex";
import { getValueWithSuffix } from "../../../../utils";

export default {
  name: "ProgressLineChart",
  components: {
    Line,
  },
  props: {
    id: {
      type: String,
      default: "progress-line-chart",
    },
    height: {
      type: String,
      default: "100",
    },
    icon: {
      type: String,
      default: "calendar-grid-58",
    },
    title: {
      type: String,
      default: "",
    },
    unitValue: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    stats: {
      type: Object,
      required: true,
      labels: Array,
      data: Array,
    },
    displayTicks: {
      type: Boolean,
      default: true,
    },
    convertUnits: {
      type: Boolean,
      default: true,
    },
    unit: {
      type: String,
      default: "W",
    }
  },
  data() {
    return {
      data: {
        labels: this.stats.labels,
        datasets: [
          {
            label: this.title,
            tension: 0.3,
            pointRadius: 2,
            pointBackgroundColor: "#0B335B",
            borderColor: "#0B335B",
            borderWidth: 2,
            backgroundColor: "rgba(0, 242, 178, .5)",
            data: this.stats.data[0],
            maxBarThickness: 6,
            fill: true,
          },
          {
            label: this.title,
            tension: 0.3,
            pointRadius: 2,
            pointBackgroundColor: "#FF0000",
            borderColor: "#FF0000",
            borderWidth: 2,
            backgroundColor: "rgba(0, 242, 178, .5)",
            data: this.stats.data[1],
            maxBarThickness: 6,
            fill: true,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (item) => {
                let labels = item.dataset.label || '';
                labels = this.convertUnits ? getValueWithSuffix(item.parsed.y, this.unit) : item.parsed.y;
                return labels;
              }
            }
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          // eslint-disable-next-line no-dupe-keys
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 20],
            },
            ticks: {
              display: this.displayTicks,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          // eslint-disable-next-line no-dupe-keys
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: this.displayTicks,
              padding: 10,
              color: "#9ca2b7",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  updated() {
    this.data.labels = this.stats.labels;
    this.data.datasets[0].data = this.stats.data[0];
    this.data.datasets[1].data = this.stats.data[1];

  },
};
</script>
<style lang="scss">
.bg-gradient-custom {
  background: linear-gradient(to right, #0B335B, #0B1B2B);

}

.value {
  font-size: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
