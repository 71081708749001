<template>
  <div class="chart">
    <Pie :id="id" :height="height" :chart-data="data" :chart-options="options" />
    <div v-if="showButtons===true" class="stats-btns">
    <div v-for="(label,index) in stats.labels"  :key="index" >
     <router-link :to="{name: 'Sites', query: { discom: selected.discom, assetType: selected.assetType,status: title==='status'? label:'ALL',operationalStatus: title==='operationalStatus'? label:'ALL' }}">
          <button
            class="btn btn-option btn-outline-primary"
            :class="btnCancelClass"           
          >
            {{ label }}
          </button>  
        </router-link>   
      </div>
    </div>
  </div>
</template>
<script>
import { Pie } from 'vue-chartjs'

export default {
  name: "PieChart",
  components: {
    Pie
  },
  props: {
    
    height: {
      type: [String, Number],
      default: "200",
    },
    title: {
      type: String,
      default: "Pie Chart",
    },
    stats: {
      type: Object,
      required: true,
      label: String,
      labels: Array,
      data: Array,
    },
    selectedItems:{
      type: Object,
      required: true,
      discom: "ALL",
      assetType: "ALL",
      dateRange: "",
    },
    showBtns:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      selected: this.selectedItems,
      showButtons: this.showBtns,
      data: {
        labels: this.stats.labels,
        datasets: [
          {
            label: this.stats.label,
            weight: 9,
            cutout: 0,
            tension: 0.9,
            pointRadius: 2,
            borderWidth: 2,
            backgroundColor: ["#0B335B", "#00F2B2", "#13B78C", "#a8b8d8"],
            data: this.stats.data,
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      }
    }
  },
  updated() {
    this.data.labels = this.stats.labels;
    this.data.datasets[0].data = this.stats.data;
    this.selected = this.selectedItems;
    this.showButtons = this.showBtns;
  },
};
</script>
<style scoped>
.stats-btns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btn-option{
  padding: 0rem 0.5rem;
  min-width: 5rem;
}
</style>
