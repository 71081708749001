const FORMATTER = Intl.DateTimeFormat("en-IN", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});
const PRECISION = 2;
const RANGES = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];

const formatDate = (date) => FORMATTER.format(new Date(date));

const getValueWithSuffix = (value, unit = "W") => {
  let result = value,
    rangeIndex = 0;
  if (result === 0 || result == null) {
    result = 0;
  }
  while (result > 0) {
    if (result < 1000) {
      break;
    }
    result /= 1000;
    rangeIndex++;
  }
  return `${result.toFixed(PRECISION)} ${RANGES[rangeIndex]}${unit}`;
};

export { formatDate, getValueWithSuffix };
