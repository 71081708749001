<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <!-- <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        /> -->
      </div>
    </div>
  </div>

  <main class="mt-0 main-content main-content-bg">
    <section class="min-vh-75">
      <div class="container">
        <div class="row">
          <div class="mx-auto col-lg-5 col-md-7">
            <div class="mb-4 card z-index-0 mt-sm-12 mt-9">
              <div class="pt-4 pb-1 text-center card-header">
                <h4 class="mb-1 font-weight-bolder">Reset password</h4>
                <p class="mb-0">
                  Kindly fill the details to reset your Password.
                </p>
              </div>
              <div class="card-body">
                <!-- <form role="form"> -->
                  <div class="mb-3">
                    <div class="form-group">
                      <input id="oldPassword" v-model="oldPassword" type="password" placeholder="Old Password" name="oldPassword"
                        class="form-control" size="lg" />
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-group">
                      <input id="newPassword" v-model="newPassword" type="password" placeholder="New Password" name="newPassword"
                        class="form-control" size="lg" />
                    </div>
                  </div>
                    <div class="text-center" style="color: red">{{ error }}</div>
                  <div class="text-center">
                     <soft-button  :loading="loading" variant="gradient" color="success" full-width size="lg" @click="resetPassword">Change Password
                    </soft-button>
                  </div>
                <!-- </form> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <!-- <app-footer /> -->
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/PageLayout/Footer.vue";
//import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
export default {
  name: "ResetBasic",
  components: {
    // Navbar,
    // AppFooter,
  //  SoftInput,
    SoftButton,
  },
   data() {
    return {
      oldPassword: "",
      newPassword: "",
      error: "",
      loading: false
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-200");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-200");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

     async resetPassword() {
      this.loading = true;
      this.error = "";
      if (this.oldPassword.length && this.newPassword.length) {
       const result =""; //await login({ username: this.username.toLowerCase(), password: this.password });
        this.loading = false;
        if (!result) {
          this.error = "The old password you added is incorrect.";
        } else {
          this.$router.push({ name: "Signin Illustration" });
        }
      }else if(!this.oldPassword.length){
         this.error = "Old Password is required!"
         this.loading = false;
      }
      else {
        this.error = "New Password is required!"
        this.loading = false;
      }
    }
  },
};
</script>
