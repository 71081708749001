<template>
  <button v-if="!loading"
    class="btn mb-0 signin-button"
    :class="getClasses(variant, color, size, fullWidth, active)"
          @click="$emit('click')"
  >
    <slot />
  </button>
  <button v-if="loading"
    class="btn mb-0"
    :class="getClasses(variant, color, size, fullWidth, active)"
          @click="false"
          style="height: 48px"
  >
    <span class="loader" style="display: block;margin: auto;margin-top: -25px;"></span>
  </button>
</template>

<script>
export default {
  name: "SoftButton",
  props: {
    color: {
      type: String,
      default: "success",
    },
    size: {
      type: String,
      default: "md",
    },
    variant: {
      type: String,
      default: "fill",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClasses: (variant, color, size, fullWidth, active) => {
      let colorValue, sizeValue, fullWidthValue, activeValue;

      // Setting the button variant and color
      if (variant === "gradient") {
        colorValue = `bg-gradient-primary`;
      } else if (variant === "outline") {
        colorValue = `btn-outline-primary`;
      } else {
        colorValue = `btn-${color}`;
      }

      sizeValue = size ? `btn-${size}` : null;

      fullWidthValue = fullWidth ? `w-100` : null;

      activeValue = active ? `active` : null;

      return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue}`;
    },
  },
};
</script>
<style lang="scss">
.signin-button {
  background: linear-gradient(to right, #0B335B, #0B1B2B)!important;

}
</style>
