<template>
  <footer class="py-4 mt-5 footer custom_footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-12 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-center'"
          >
            ©
            {{ new Date().getFullYear() }} Trillectric | Terms and Privacy Policy
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
<style lang="scss">
.custom_footer{
      background-color: #fff;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 2;

}
</style>
