<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item mb-3">
        <sidenav-collapse
          collapse-ref="dashboardsExamples"
          nav-text="Dashboards"
          :class="getRoute() === 'generalDashboard' ? 'active' : ''"
        >
          <template #icon>
           <label class="sidenav_icon mb-0"><img class="dashbpard" src="../../assets/img/home.svg" alt="home" /></label>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'General Dashboard' }"
                mini-icon="D"
                text="General"
                class="submenu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item mb-3">
        <sidenav-collapse
          collapse-ref="AMCExamples"
          nav-text="AMC"
          :class="getRoute() === 'amcDashboard' ? 'active' : ''"
        >
          <template #icon>
           <label class="sidenav_icon mb-0"><img class="dashbpard" src="../../assets/img/home.svg" alt="home" /></label>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'AMC Dashboard' }"
                mini-icon="D"
                text="AMC Dashboard"
                class="submenu"
              />
              <sidenav-item
                :to="{ name: 'AMC LIST'}"
                mini-icon="D"
                text="AMC List"
                class="submenu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item mb-3">
        <sidenav-collapse
          collapse-ref="sitesExamples"
          nav-text="Sites"
          :class="getRoute() === 'sites' ? 'active' : ''"
        >
          <template #icon>
          <label class="sidenav_icon mb-0"><img class="dashbpard" src="../../assets/img/home.svg" alt="home" /></label>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                 :to="{
                    name: 'Sites',                   
                  }"
                mini-icon="D"
                text="All"
                class="submenu"
              />
              <sidenav-item
                 :to="{
                    name: 'Sites',
                    query: {
                      status: 'ONLINE',
                    },
                  }"
                mini-icon="D"
                text="Online"
                class="submenu"
              />
              <sidenav-item
                 :to="{
                    name: 'Sites',
                    query: {
                       status: 'OFFLINE',
                    },
                  }"
                mini-icon="D"
                text="Offline"
                class="submenu"
              />
              <sidenav-item
                 :to="{
                    name: 'Sites',
                    query: {
                      operationalStatus: 'NORMAL',
                    },
                  }"
                mini-icon="D"
                text="Normal"
                class="submenu"
              />
              <sidenav-item
                 :to="{
                    name: 'Sites',
                    query: {
                      operationalStatus: 'ALERT',
                    },
                  }"
                mini-icon="D"
                text="Alerts"
                class="submenu"
              />
              <sidenav-item
                 :to="{
                    name: 'Sites',
                    query: {
                      amc: 'AMC',
                    },
                  }"
                mini-icon="D"
                text="AMC"
                class="submenu"
              />
              <sidenav-item
                 :to="{
                    name: 'Sites',
                    query: {
                      amc: 'AMC_RENEWAL',
                    },
                  }"
                mini-icon="D"
                text="Amc Renewal"
                class="submenu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>



      <!-- <li class="nav-item mb-3">
        <router-link :to="{ name: 'Sites' }">
           <label class="sidenav_icon mb-0"><img src="../../assets/img/insights.svg" alt="insights" /></label>
            <span>Sites</span>
        </router-link>
      </li> -->
      <!-- <li class="nav-item mb-3">
        <router-link to="/pages/profile/teams">
           <label class="sidenav_icon mb-0"><img src="../../assets/img/insights.svg" alt="insights" /></label>
            <span>Insights</span>
        </router-link>
      </li> -->
      <!-- <li class="nav-item mb-3">
       <router-link to="/pages/profile/teams">
           <label class="sidenav_icon mb-0"><img src="../../assets/img/alerts.svg" alt="alerts" /></label>
            <span>Alerts</span>
        </router-link>
      </li>
      <li class="nav-item mb-3">
         <router-link to="/pages/profile/teams">
           <label class="sidenav_icon mb-0"><img src="../../assets/img/entities.svg" alt="alerts" /></label>
            <span>Assets</span>
        </router-link>
      </li>
      <li class="nav-item mb-3">
      <router-link to="/pages/profile/teams">
           <label class="sidenav_icon mb-0"><img src="../../assets/img/entities.svg" alt="alerts" /></label>
            <span>Entities</span>
        </router-link>
      </li>
      <li class="nav-item mb-3">
      <router-link to="/pages/profile/teams">
           <label class="sidenav_icon mb-0"><img src="../../assets/img/task.svg" alt="task" /></label>
            <span>Actionable Tasks</span>
        </router-link>
      </li>
      <li class="nav-item mb-3">
      <router-link to="/pages/profile/teams">
           <label class="sidenav_icon mb-0"><img src="../../assets/img/reports.svg" alt="reports" /></label>
            <span>Reports</span>
        </router-link>
      </li> -->
    </ul>
  </div>
  <!-- <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      text-primary="Need Help?"
      text-secondary="Please check our docs"
      route="https://www.creative-tim.com/learning-lab/vue/overview/soft-ui-dashboard/"
      label="Documentation"
      icon="ni ni-diamond"
    />
  </div> -->
</template>
 <script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";
// import Settings from "../../components/Icon/Settings.vue";
// import Basket from "../../components/Icon/Basket.vue";
// import Box3d from "../../components/Icon/Box3d.vue";
// import Office from "../../components/Icon/Office.vue";
// import Document from "../../components/Icon/Document.vue";
// import Spaceship from "../../components/Icon/Spaceship.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";

import { mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    // SidenavCard,
    // SidenavCollapseItem,
    // Settings,
    // Basket,
    // Box3d,
    // Shop,
    // Office,
    // Document,
    // Spaceship,
    // CreditCard,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[2];
      
    },
  },
};
</script>
<style lang="scss">
.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  display: none !important;
}
.g-sidenav-show.g-sidenav-hidden span{
  display:none;
}
.g-sidenav-show.g-sidenav-pinned span{
  display: block;
}
.g-sidenav-show.g-sidenav-hidden .nav-item a svg{
  margin-right:0px;
}
.g-sidenav-show.g-sidenav-hidden{
  .nav-item a.active.router-link-exact-active{
    box-shadow:none;
    background: unset;
  }
  .nav-item a{
    margin:0;
  }
}
.nav-item {
  a{
    border-radius: 0.5rem;
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    margin: 0 1rem;
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: black;
    display: flex;
    label {
    background: white;
    box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07) !important;
    height: 47px;
    width: 47px;
    padding: 13px; 
    border-radius: 9px;
    margin-right: 16px;
    display: flex;
    img{
      filter: invert(0);
    }
    img.dashbpard{
      filter: invert(1);
    }
    .color-background {
    fill: #3A416F;
}
}
  }
a.active.router-link-exact-active {
    background-color: #fff;
    font-weight: 600;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    label{     
      background: linear-gradient(179deg, #18A2F0 0.9%, #3469EF 98.77%);
      box-shadow:unset;
    img{
      filter: invert(1);
    }
      .color-background {
        fill: white;
}
    }
}
}
img.dashbpard{
      filter: invert(0);
}
a.nav-link.active{
  // padding: 16px 28px!important;
  span{
    font-size:16px;
    color:black;
  }
    label{
      background: linear-gradient(to right, #0B335B, #0B1B2B);
       img.dashbpard{
         filter: invert(0);
    }
    }
}
li.nav-item.submenu {
    padding-top: 10px;
    span{
      font-weight: 400;
    }
}
.nav-link-text{
  font-size: 16px;
  color: black;
}

.g-sidenav-show{

.sidenav{
  z-index:1;
}
}
</style>
